// extracted by mini-css-extract-plugin
export var ammountDiv = "shipping-policy-module--ammountDiv--c8fa5";
export var ammountDiv1 = "shipping-policy-module--ammountDiv1--d8729";
export var businessDaysP = "shipping-policy-module--businessDaysP--53c5e";
export var call = "shipping-policy-module--call--712fd";
export var callPhoneNumber = "shipping-policy-module--callPhoneNumber--65f22";
export var callPhoneNumberOrAlertU = "shipping-policy-module--callPhoneNumberOrAlertU--f98f3";
export var caption = "shipping-policy-module--caption--92eae";
export var caption2Div = "shipping-policy-module--caption2Div--5f708";
export var captionB4 = "shipping-policy-module--captionB4--7d6bc";
export var captionDiv = "shipping-policy-module--captionDiv--02f01";
export var displayB = "shipping-policy-module--displayB--fa999";
export var displayDiv = "shipping-policy-module--displayDiv--0f116";
export var displayDiv3 = "shipping-policy-module--displayDiv3--91116";
export var docTemplateDiv = "shipping-policy-module--docTemplateDiv--79375";
export var orderAmountP = "shipping-policy-module--orderAmountP--fe4b2";
export var pDiv = "shipping-policy-module--pDiv--dede4";
export var pDiv1 = "shipping-policy-module--pDiv1--d78dc";
export var pDiv14 = "shipping-policy-module--pDiv14--56bc4";
export var pDiv2 = "shipping-policy-module--pDiv2--9af97";
export var pDiv20 = "shipping-policy-module--pDiv20--c21ce";
export var pDiv7 = "shipping-policy-module--pDiv7--b141e";
export var privacyPolicyB = "shipping-policy-module--privacyPolicyB--9107d";
export var shippingPolicyDiv = "shipping-policy-module--shippingPolicyDiv--21c69";
export var span = "shipping-policy-module--span--def68";
export var subtitleDiv2 = "shipping-policy-module--subtitleDiv2--06b78";
export var subtitleLargeDiv = "shipping-policy-module--subtitleLargeDiv--f5b34";
export var textColumnDiv = "shipping-policy-module--textColumnDiv--dd440";
export var textbodyDiv = "shipping-policy-module--textbodyDiv--35683";
export var textbodyDiv2 = "shipping-policy-module--textbodyDiv2--ea8d5";
export var textbodyDiv4 = "shipping-policy-module--textbodyDiv4--c8171";
export var textbodyDiv6 = "shipping-policy-module--textbodyDiv6--525b0";
export var titleDiv = "shipping-policy-module--titleDiv--8c40a";